import React from "react";

import Layout from "../components/layout-main";
import {
  Faqs,
  WHAT_NOW_QUESTIONS,
  VIRTUAL_SERVICE_QUESTIONS,
} from "../components/faqs";

const ThisPage = ({ location }) => {
  return (
    <Layout location={location} pageTitle="FAQs">
      <Faqs
        variant="accordion"
        questions={VIRTUAL_SERVICE_QUESTIONS.concat(WHAT_NOW_QUESTIONS)}
      />
    </Layout>
  );
};

export default ThisPage;
